<template>
  <div>
    <v-container class="text-center mb-12">
      <Loader :loading="loading" />
      <div class="subtitle pa-4 mb-2">
        <h2>Solicitud {{user_data.id}} -  {{user_data.empleado}}</h2>
      </div>
      <div class="text-left">
        <Basic-Btn text="Volver" color="primary"  @click="$router.push('/solicitud-empleados')" :loading="loading" alignIcon  typeButton="text" icon="mdi-arrow-left"/>
      </div>
      <v-row>
        <v-col :cols="user_data.dias > 0 ? 4 : 6" xs="12" class="py-0">
          <v-card class="pa-0 my-6 text-left primary--text">
            <hr />
            <div class="pa-4">
              Tipo de solicitud
              <v-row>
                <v-col cols="12" xs="12"> <h3 v-text="user_data.tipo_solicitud"></h3> </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="user_data.dias > 0 ? 4 : 6" xs="12" class="py-0">
          <v-card class="pa-0 my-6 text-left primary--text">
            <hr />
            <div class="pa-4">
              Subtipo de solicitud
              <v-row>
                <v-col cols="12" xs="12"> <h3 v-text="user_data.subtipo_solicitud"></h3> </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" xs="12" class="py-0" v-if="user_data.dias > 0">
          <v-card class="pa-0 my-6 text-left primary--text">
            <hr />
            <div class="pa-4">
              Total de días solicitados
              <v-row>
                <v-col cols="12" xs="12"> <h3 v-text="user_data.dias.toString() + user_data.medio_dia"></h3> </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="pa-0 my-6 text-left primary--text">
        <hr />
        <div class="pa-4">
          <div class="text-center">
            <h2 class="linea"><span>Datos generales</span></h2>
          </div>
          <v-row>
            <v-col cols="4" xs="12">
              <v-text-field label="Puesto" dense outlined class="mr-2 ml-2 mb-2 disabled-pointer" hide-details v-model="user_data.puesto"></v-text-field>
            </v-col>
            <v-col cols="4" xs="12">
              <v-text-field label="Departamento" dense outlined class="mr-2 ml-2 mb-2  disabled-pointer" hide-details v-model="user_data.departamento"></v-text-field>
            </v-col>
            <v-col cols="4" xs="12">
              <v-text-field label="Coordinador" dense outlined class="mr-2 ml-2 mb-2  disabled-pointer" hide-details v-model="user_data.jefe"></v-text-field>
            </v-col>
             <v-col cols="12" xs="12">
              <v-textarea label="Motivo" dense outlined class="mr-2 ml-2 mb-2 disabled-pointer" hide-details v-model="user_data.comentarios"></v-textarea>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-card class="pa-0 my-6 text-left primary--text" v-if="employeeDates.length > 0">
        <hr />
        <div class="text-center">
          <h2 class="linea"><span>Fechas solicitadas</span></h2>
        </div>
        <v-data-table :headers="headersDates" :items="employeeDates" hide-default-footer :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="20" item-key="id" class="elevation-1" dense>
          <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
          <template v-slot:[`item.fecha_inicio`]="{ item }">
            {{ dateFormat(item.fecha_inicio) }}
          </template>
          <template v-slot:[`item.fecha_fin`]="{ item }">
            {{ dateFormat(item.fecha_fin) }}
          </template>
        </v-data-table>
      </v-card> 
      <v-card class="pa-0 my-6 text-left primary--text" v-if="Object.keys(detailVacation).length">
        <hr />
        <div class="text-center">
          <h2 class="linea"><span>Detalle de vacaciones</span></h2>
        </div>
        <v-row class="text-center">
          <v-col cols="12">
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="primary--text">Años de antigûedad</td>
                  <td>{{ detailVacation.antiguedad }}</td>
                </tr>
                <tr>
                  <td class="primary--text">Saldo de días (actual)</td>
                  <td>{{ detailVacation.saldo }}</td>
                </tr>
                <tr v-if="!isApproved">
                  <td class="primary--text">Saldo de días (después de aprobación)</td>
                  <td>{{ detailVacation.saldo - user_data.dias }}</td>
                </tr>
                <tr>
                  <td class="primary--text">Movimientos</td>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="showMoves" v-bind="attrs" v-on="on" icon color="info" @click="showMoves = !showMoves"><v-icon>mdi-eye</v-icon></v-btn>
                        <v-btn v-else v-bind="attrs" v-on="on" icon @click="showMoves = !showMoves"><v-icon>mdi-eye-off</v-icon></v-btn>
                      </template>
                      <span>{{ (!showMoves) ? 'Mostrar movimientos' : 'Ocultar movimientos' }}</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="showMoves" class="text-center">
          <v-col cols="12">
            <v-card class="mx-4">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-center">Fecha inicio</th>
                    <th class="text-center">Fecha fin</th>
                    <th class="text-center">Días</th>
                    <th class="text-center">Origen</th>
                    <th class="text-center">Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, idx) in detailVacation.movimientos">
                    <tr>
                      <td>{{ item.fecha_inicio }}</td>
                      <td>{{ (item.fecha_fin) ? item.fecha_fin : 'N/A' }}</td>
                      <td>{{ item.dias }}</td>
                      <td>{{ item.origen }}</td>
                      <td>{{ item.tipo }}</td>
                    </tr>
                  </template>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-0 my-6 text-left primary--text" v-if="!Object.keys(extraTime).length && switchDates.length && switchDates[0] && switchDates[1]">
        <hr />
        <div class="text-center">
          <h2 class="linea"><span>Fechas de intercambio</span></h2>
        </div>
        <v-row class="text-center">
          <v-col cols="4">
            Nuevo día de descanso: <strong>{{ switchDates[0] }}</strong>
          </v-col>
          <v-col cols="4">
            <v-icon color="info">mdi-compare-horizontal</v-icon>
          </v-col>
          <v-col cols="4">
            Día de descanso a intercambiar: <strong>{{ switchDates[1] }}</strong>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-0 my-6 text-left primary--text" v-if="Object.keys(extraTime).length && switchDates.length && switchDates[0] && switchDates[1]">
        <hr />
        <div class="text-center">
          <h2 class="linea"><span>Tiempo extra</span></h2>
        </div>
        <v-row class="text-center">
          <v-col cols="4">
            <template v-for="(item, index) in extraTime">
              <p :key="index">
                Se consumirán <strong>{{ item }}</strong> horas del día: <strong>{{ index }}</strong>
              </p>
            </template>
          </v-col>
          <v-col cols="4">
            <v-icon color="info">mdi-compare-horizontal</v-icon>
          </v-col>
          <v-col cols="4">
            <p>
              Día a intercambiar: <strong>{{ switchDates[1] }}</strong><br>
            </p>
            <p>
              Hora de inicio Tiempo Extra: <strong>{{ startHour }}</strong><br> 
            </p>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-0 my-6 text-left primary--text" v-if="requestedSchedule">
        <hr />
        <div class="text-center">
          <h2 class="linea"><span>Horario solicitado</span></h2>
        </div>
        <v-row class="text-center">
          <v-col cols="12">
            <p class="mt-3">{{ requestedSchedule }}</p>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-0 my-6 text-left primary--text">
        <hr />
        <div class="text-center">
          <h2 class="linea"><span>Flujo de aprobación</span></h2>
        </div>
        <v-data-table :headers="headersFlow" :items="flowSubtype" hide-default-footer :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="20" item-key="id" class="elevation-1" dense>
          <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
          <template v-slot:no-data> No hay información disponible </template>
          <template v-slot:no-results> No se obtuvieron resultados </template>
        </v-data-table>
      </v-card>
      <v-card class="pa-0 my-6 text-left primary--text">
        <hr />
        <div class="pa-4">
          <div class="text-center">
            <h2 class="linea"><span>Observaciones</span></h2>
          </div>
         <br>
         <div class="text-right">
            <v-textarea v-model="form.comentarios" label="Comentarios" rows="2" prepend-icon="mdi-comment-outline" hide-details dense outlined></v-textarea>
          </div>
          <br />
          <v-row>
          </v-row>
        </div>
      </v-card>
    </v-container>
    <div v-if="!isApproved" class="footer">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="4" xs="6">
          <Basic-Btn text="Rechazar" color="error" @click="save('Rechazado')" :loading="loading" block xLarge />
        </v-col>
        <v-col cols="4" xs="6">
          <Basic-Btn text="Aprobar" color="success" @click="save('Aprobado')" :loading="loading" block xLarge />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </div>
</template>

<script>
import "animate.css";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage, HourPicker } from "@/components";
import services from "@/utils/services";
import moment from "moment";

export default {
  name: "detail-employee-request",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
    HourPicker,
  },
  data: () => ({
    loading: false,
    request: "",
    completed: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    form: {
      idsolicitud_empleado_externo: "",
      status: "",
      comentarios: "",
    },
    user_data: {
      empleado: "",
      departamento: "",
      puesto: "",
      jefe: "",
    },
    schedulesTypes: [],
    flowSubtype: [],
    employeeDates:[],
    switchDates: ['', ''],
    extraTime: {},
    startHour: null,
    detailVacation: {},
    showMoves: false,
    requestedSchedule: null,
    isApproved: false,
    headersFlow: [
      { text: "Orden", align: "center", sortable: true, value: "orden" },
      { text: "Encargado", align: "center", sortable: false, value: "nombre_completo" },
      { text: "Correo de encargado", align: "center", sortable: false, value: "email" },
    ],
    headersDates: [
      { text: "Fecha inicio", align: "center", sortable: true, value: "fecha_inicio" },
      { text: "Fecha fin", align: "center", sortable: true, value: "fecha_fin" },
    ]
  }),
  mounted() {
    this.form.idsolicitud_empleado_externo = this.$route.params.id
    this.getDetail(this.$route.params.id);
  },
  methods: {
    async getDetail(id) {
      this.loading = true;
      const st = await this.axios.get(services.routes.scheduleType)
      if (st.data.data) {
        this.schedulesTypes = st.data.data
      }
      this.axios
        .get(services.routes.employeeRequest + "/detail/" + id)
        .then((response) => {
          let items = response.data.data;
          let employee_details = items.empleado_solicita.detalles_usuarios[0];
          this.user_data = {
            id: items.idsolicitud_empleado,
            dias: items.dias,
            medio_dia: '',
            subtipo_solicitud: items.subtipo_solicitud.nombre,
            tipo_solicitud: items.subtipo_solicitud.tipo_solicitud.nombre,
            empleado: employee_details.nombre + " " + employee_details.apellido_paterno + " " + employee_details.apellido_materno,
            departamento: employee_details.departamento.nombre,
            puesto: employee_details.puesto.nombre,
            jefe: employee_details.departamento.jefe.detalles_usuarios[0].nombre + " " + employee_details.departamento.jefe.detalles_usuarios[0].apellido_paterno + " " + employee_details.departamento.jefe.detalles_usuarios[0].apellido_materno,
            comentarios: items.comentarios,
          };
          this.employeeDates = items.detalle_solicitud_empleado;
          if (this.employeeDates.length) {
            const diff = moment(this.employeeDates[0].fecha_fin).diff(moment(this.employeeDates[0].fecha_inicio), 'hours')
            if (diff == 4) {
              this.user_data.medio_dia = ' (medio día)'
            }
          }
          if (this.user_data.subtipo_solicitud === 'VACACIONES') {
           this.detailVacation = items.detalle_vacaciones;
          }
          else if (this.user_data.subtipo_solicitud === 'CAMBIO POR DESCANSO SÁBADO') {
            const aux = JSON.parse(items.data)
            this.switchDates = (aux) ? aux.fechas_cambio : []
          }
          else if (this.user_data.subtipo_solicitud === 'CAMBIO TIEMPO EXTRA') {
            const aux = JSON.parse(items.data)
            this.switchDates = (aux) ? aux.fechas_cambio : []
            this.extraTime = (aux) ? aux.horas_extra : {}
            this.startHour = (aux) ? aux.hora_inicio : null
          }
          else if (this.user_data.subtipo_solicitud === 'CAMBIO DE HORARIO') {
            const aux = JSON.parse(items.data)
            const id = (aux) ? aux.tipo_horario : null
            if (id) {
              for (let schedule of this.schedulesTypes) {
                if (schedule.idtipo_horario == id) {
                  this.requestedSchedule = schedule.nombre
                }
              }
            }
          }
          this.flowSubtype = items.flujo_autorizaciones.data.flujo_aprobacion;
          let approvalFlow = _.get(items, 'flujo_autorizaciones.data.flujo_aprobacion', []);
          let tmpPending = approvalFlow.find((e) => e.status == 'Pendiente');
          let tmpRefused = approvalFlow.find((e) => e.status == 'Rechazado');
          if (tmpPending === undefined || tmpRefused.length) {
            this.isApproved = true
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async save(type) {
      this.loading = true;
      let data = JSON.parse(JSON.stringify(this.form));
      data.status = type
      this.axios
        .post(services.routes.employeeRequest + "/update/status", { data })
        .then((response) => {
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Solicitud  guardada exitosamente";
          this.$router.push({
            path: "/solicitud-empleados",
          });
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss A");
    },
  },
};
</script>

<style scoped>
  hr {
    border-left: 8px solid #6c1414;
    height: 100%;
    position: absolute;
    border-color: none;
  }
  h2.linea {
    position: relative;
    z-index: 1;
  }
  h2.linea:before {
    border-top: 2px solid #6c1414;
    border-width: 5px;
    width: 40%;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  h2.linea span {
    background: #fff;
    padding: 0 15px;
  }
  .disabled-pointer{
    pointer-events: none
  }
</style>
